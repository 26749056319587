<template>
  <view-wrapper class="press" header-text="What the press is saying about THE WEST IS BURNING">
    <div class="press__controls">
      <div
        v-for="filter in filters"
        role="button"
        @click="() => filterBy = filter"
        :key="filter"
        :class="{
          'press__controls-item': true,
          'press__controls-item--active': filterBy === filter
        }"
      >
       {{ filter }}
      </div>
    </div>

    <v-progress-circular 
      v-if="loading"
      :size="60"
      indeterminate>
    </v-progress-circular>

    <div class="press__no-press" v-else-if="!pressItems.length"><em>No press items.</em></div>
    
    <div class="press__item"
      v-for="press in pressItems"
      :key="press.id"
    >
      <testimonial>
        {{ press.title }}
        <template #name>
          {{ press.field_organization }}
          <a 
            v-if="press.field_source_url"
            class="press__source-url"
            :href="press.field_source_url"
            target="_blank"
            rel="noopener"
          >
            <v-icon>open_in_new</v-icon>
          </a>
        </template>
        <template #extra v-if="press.field_date">
          {{ dateFormatted(press.field_date) }}
        </template>
      </testimonial>

      <v-divider/>
    </div>

    <h2 class="mb-3" style="margin-top: 75px">Press Kit</h2>
    <p>Get the entire package that includes photo stills from the film, a poster and postcard, as well as detailed notes about the film.</p>
    <v-btn href="https://drive.google.com/drive/folders/1rochX-Z067h88IDMBt8aqIN5rcWjy0Nt?usp=sharing" color="accent" depressed class="btn-extra-wide ml-0" target="_wib_presskit">download press kit</v-btn>
  </view-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  allPress
} from '@/api/utils/queries'

import Testimonial from '@/components/Testimonial/Testimonial'

import moment from 'moment'

export default {
  name: 'press',

  components: {
    Testimonial
  },

  created() {
    this.loading = true
    this.fetchContentTypeAll({ type: this.type, filter: allPress })
      .then(() => {
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  },

  data() {
    return {
      loading: false,
      type: 'press',
      filters: ['All', 'Reviews', 'Interviews'],
      filterBy: 'All'
    }
  },

  computed: {
    ...mapGetters(['getContentTypeByType']),
    pressItems () {
      return this.getContentTypeByType('press').filter(p => {
        return this.filterBy === 'All'
          ? true
          : p.field_press_type === this.filterBy
      })
    }
  },

  methods: {
    ...mapActions(['fetchContentTypeAll']),
    dateFormatted (date) {
      return moment(date).format('MM/DD/YYYY')
    }
  }
}
</script>

<style lang="stylus">
  .press
    .press__item
      .testimonial
        margin-bottom: 10px

        blockquote
          margin-bottom: 10px !important
    .press__source-url
      text-decoration: none

    .press__controls
      margin-top: 100px
      display: flex
      align-items: center
      text-transform: uppercase
      font-size: 14px

      &-item
        opacity: 0.5
        margin-right: 20px

      &-item--active
        opacity: 1
        font-weight: bold
    
    .press__no-press
      margin-top: 15px
</style>
