<template>
  <div class="testimonial">
    <div class="testimonial__quote" v-if="quote">&ldquo;</div>
    <div class="testimonial__wrapper">
      <blockquote class="mb-4">
        <slot></slot>
      </blockquote>
      <cite>
        <p class="mb-0">
          <strong><slot name="name"></slot></strong>
        </p>
        <p><slot name="extra"></slot></p>
      </cite>
    </div>
  </div>
</template>

<script>
  export default {
    name: "testimonial",

    props: {
      quote: Boolean,
      name: String,
      title: String,
      extraInfo: String,
    }
  }
</script>

<style lang="stylus">
.testimonial
  .testimonial__quote
    font-size: 100px
    display inline-block
    width 50px
    vertical-align text-top
    margin-top -7px
    margin-left -7px
    // if you remove this colon, in doesn't compile, maybe we should put colons if we can determine this issue
    color: $theme.accent
    font-family: $secondary-font-family

  .testimonial__wrapper
    display inline-block
    width calc(100% - 44px);
    vertical-align text-top
    margin-top 30px

  blockquote
    font-size 28px
    font-family: $secondary-font-family
    line-height 1.3
    font-weight bold
    color $color-middle-grey

  cite p
    font-style normal 
    font-size 14px
    strong
      font-size 16px
</style>